import PropTypes from 'prop-types';
import React from 'react';

import styles from './Field.css';
import {visuallyhidden} from '../shared-styles/_visuallyhidden.css';
import ErrorList from './ErrorList';


export default function Field(props) {
    const {
        style, type, slug, label, children, helpText, errors, hideLabel, hideErrors,
    } = props;

    const isInvalid = errors ? true : null;
    const describedBy = [
        helpText && `${slug}--help`,
        errors && `${slug}--errors`,
    ].join(' ').trim() || null;

    const helpElement = helpText ?
        <span key="help" className={styles.helpText} id={`${slug}--help`}>{helpText}</span> :
        null;
    const labelElement = (
        <label key={label} className={hideLabel ? visuallyhidden : styles.label} htmlFor={slug}>
            <span className={styles.primaryLabelText}>{label}</span>
            {helpElement}
        </label>
    );
    const childElements = React.Children.map(children, child => {
        return React.cloneElement(child, {describedBy, isInvalid});
    });
    const errorElement = (errors && errors.length > 0 && !hideErrors) ?
        <ErrorList key="errors" errors={errors} slug={slug} /> :
        null;

    // Radio should come before its label
    // in rendered DOM element order
    const fieldContents = type === 'radio' ?
        [errorElement, ...childElements, labelElement] :
        [labelElement, errorElement, ...childElements];

    return (
        <div className={style || styles.field}>
            {fieldContents}
        </div>
    );
}

Field.displayName = 'Field';

Field.propTypes = {
    style: PropTypes.string,
    type: PropTypes.string,
    slug: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired,
    helpText: PropTypes.string,
    errors: PropTypes.arrayOf(PropTypes.string),
    hideLabel: PropTypes.bool,
    hideErrors: PropTypes.bool,
};
