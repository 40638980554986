import PropTypes from 'prop-types';
import React from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';

import {FadeInOnMount} from '@energysavvy/customer-ui-components';

import * as actions from '../actions';
import IntroForm from './IntroForm';
import SurveySummary from '../components/SurveySummary.jsx';

import styles from './Intro.css';

function Intro({config}) {
    return (
        <FadeInOnMount className={styles.wrapper} role="main">
            <h1 className={styles.heading}>{config.summaryTitle}</h1>
            <SurveySummary summaryContent={config.summaryContent} />
            <IntroForm />
        </FadeInOnMount>
    );
}

Intro.propTypes = {
    config: PropTypes.shape({
        summaryTitle: PropTypes.string.isRequired,
        summaryContent: PropTypes.string.isRequired,
        otherHomeTypeDetail: PropTypes.string.isRequired,
    }),
};

function mapStateToProps(state) {
    return {
        config: state.config,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(actions, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(Intro);
