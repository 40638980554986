import PropTypes from 'prop-types';
import React from 'react';

import styles from './ErrorList.css';


export default function ErrorList({errors, slug}) {
    return (
        <div role="alert" key={`${slug}--errors`} id={`${slug}--errors`}>
            <ul className={styles.list}>
                {errors.map(
                    error => <li key={error} className={styles.item}>{error}</li>
                )}
            </ul>
        </div>
    );
}

ErrorList.displayName = 'ErrorList';

ErrorList.propTypes = {
    errors: PropTypes.arrayOf(PropTypes.string).isRequired,
    slug: PropTypes.string.isRequired,
};
