import PropTypes from 'prop-types';
import React from 'react';
import omit from 'lodash/omit';

import styles from './Input.css';


export default function Input(props) {
    const {
        slug, type, name, value, onChange,
        describedBy, isInvalid, isRequired, onBlur,
        onFocus,
    } = props;
    const propKeys = Object.keys(Input.propTypes);
    const extraHTMLAttrs = omit(props, propKeys);

    const ariaAttrs = {
        'aria-describedby': describedBy,
        'aria-invalid': isInvalid,
        'aria-required': isRequired,
    };

    return (
        <input
            className={type === 'radio' ? styles.radio : styles.input}
            name={name || slug}
            id={slug}
            type={type}
            value={value}
            onChange={onChange}
            onBlur={onBlur}
            onFocus={onFocus}
            {...extraHTMLAttrs}
            {...ariaAttrs}
        />
    );
}

Input.displayName = 'Input';

Input.propTypes = {
    slug: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    name: PropTypes.string,
    value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
    ]),
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    onFocus: PropTypes.func,
    describedBy: PropTypes.string,
    isInvalid: PropTypes.bool,
    isRequired: PropTypes.bool,
};
