import PropTypes from 'prop-types';
import React from 'react';

import Markdown from '@energysavvy/markdown-to-react';

import styles from './SurveySummary.css';


function SurveySummary({summaryContent}) {
    return (
        <div className={styles.summary}>
            <Markdown content={summaryContent} />
        </div>
    );
}

SurveySummary.displayName = 'SurveySummary';

SurveySummary.propTypes = {
    summaryContent: PropTypes.string,
};

export default SurveySummary;
