import PropTypes from 'prop-types';
import React from 'react';

import ErrorList from './ErrorList';
import Fieldset from './Fieldset.jsx';
import InputField from './InputField.jsx';

import styles from './RadioGroup.css';


export default function RadioGroup(props) {
    const {
        slug, options, legend, errors,
        isRequired, value, onChange,
    } = props;


    const isInvalid = errors ? true : null;
    const radioInputs = options.map(option => {
        return (
            <InputField
                style={styles.field}
                type="radio"
                slug={option.value}
                label={option.label}
                key={option.value}
                value={option.value}
                helpText={option.helpText}
                checked={option.value === value}
                onChange={onChange}
                name={slug}
            />
        );
    });
    const errorList = (errors && errors.length > 0) && <ErrorList errors={errors} slug={slug} />;
    return (
        <Fieldset legend={legend} role="radiogroup" isInvalid={isInvalid} isRequired={isRequired}>
            {radioInputs}
            {errorList}
        </Fieldset>
    );
}

RadioGroup.displayName = 'RadioGroup';

RadioGroup.propTypes = {
    slug: PropTypes.string.isRequired,
    options: PropTypes.arrayOf(PropTypes.shape({
        value: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
        help_text: PropTypes.string,
    })).isRequired,
    legend: PropTypes.string.isRequired,
    errors: PropTypes.arrayOf(PropTypes.string),
    isRequired: PropTypes.bool,
    value: PropTypes.string,
    onChange: PropTypes.func,
};

RadioGroup.defaultProps = {
    onChange() {},
};
