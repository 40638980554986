import PropTypes from 'prop-types';
import React from 'react';
import ReactCSSTransitionReplace from 'react-css-transition-replace';

import Markdown from '@energysavvy/markdown-to-react';

import * as HOME_TYPES from '../constants/homeTypes';
import getText from '../language';
import RadioGroup from './fields/RadioGroup.jsx';
import PropertyForm from './PropertyForm.jsx';
import {fadeReplace, fadeReplaceTxIn, fadeReplaceTxOut} from './shared-styles/_animations.css';


import styles from './IntroForm.css';
import {FormSpy} from 'react-final-form';


function handleHomeTypeChange(event, changeHomeType, change) {
    const homeType = event.target.value;
    changeHomeType(homeType);
    if (homeType === 'apartment_or_condo') {
        change('floors', undefined);
    } else if (['single_family', 'townhome'].indexOf(homeType) > -1) {
        change('building_height', undefined);
    }
}

export default class IntroForm extends React.Component {
    constructor() {
        super();
        this.state = {surveyPathErrors: []};
        this.handleSubmitErrors = this.handleSubmitErrors.bind(this);
    }

    handleSubmitErrors(form) {
        const {submitErrors = {}} = form;
        this.setState({surveyPathErrors: submitErrors.survey_path});
    }

    render() {
        const {
            otherHomeTypeDetail,
            questions,
            groups,
            componentMapping,
            errorMessages,
            isSubmitting,
            handleSubmit,
            homeTypes,
            homeType,
            changeHomeType,
            form,
        } = this.props;

        let selectedHomeTypeDetail;
        switch (homeType) {
            case HOME_TYPES.OTHER:
                selectedHomeTypeDetail = (
                    <Markdown content={otherHomeTypeDetail} key={homeType} />
                );
                break;
            default:
                selectedHomeTypeDetail = (
                    <PropertyForm
                        key={homeType}
                        questions={questions}
                        groups={groups}
                        componentMapping={componentMapping}
                        errorMessages={errorMessages}
                        isSubmitting={isSubmitting}
                    />
                );
                break;
        }

        return (
            <form className={styles.form} onSubmit={handleSubmit} noValidate>
                <FormSpy
                    subscription={{
                        submitErrors: true,
                    }}
                    onChange={this.handleSubmitErrors}
                />
                <RadioGroup
                    options={homeTypes}
                    slug="survey_path"
                    legend={`${getText('home_type')}*`}
                    value={homeType}
                    onChange={e => handleHomeTypeChange(e, changeHomeType, form.change)}
                    errors={this.state.surveyPathErrors}
                    isRequired
                />
                <div aria-live="polite" aria-relevant="all">
                    <ReactCSSTransitionReplace
                        component="div"
                        transitionName={fadeReplace}
                        transitionEnterTimeout={Number(fadeReplaceTxOut) + Number(fadeReplaceTxIn)}
                        transitionLeaveTimeout={Number(fadeReplaceTxOut)}
                    >
                        {selectedHomeTypeDetail}
                    </ReactCSSTransitionReplace>
                </div>
            </form>
        );
    }
}

IntroForm.displayName = 'IntroForm';

IntroForm.propTypes = {
    changeHomeType: PropTypes.func.isRequired,
    errorMessages: PropertyForm.propTypes.errorMessages,
    homeType: PropTypes.string,
    isSubmitting: PropTypes.bool.isRequired,
    otherHomeTypeDetail: PropTypes.string.isRequired,
    homeTypes: PropTypes.arrayOf(
        PropTypes.shape({
            value: PropTypes.oneOf(Object.values(HOME_TYPES)),
            label: PropTypes.string,
            help_text: PropTypes.string,
        })
    ),
    handleSubmit: PropTypes.func.isRequired,
    questions: PropertyForm.propTypes.questions,
    groups: PropertyForm.propTypes.groups,
    componentMapping: PropertyForm.propTypes.componentMapping,
    form: PropTypes.shape({
        change: PropTypes.func.isRequired,
    }),
};
