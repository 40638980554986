import '@energysavvy/savvy-cleanslate';

import React from 'react';
import ReactDOM from 'react-dom';
import {Provider} from 'react-redux';
import registerInactivityLogout from '@energysavvy/optix-inactivity-logout-ui';
import '@energysavvy/optix-inactivity-logout-ui/lib/index.css';
import ErrorBoundary from '@energysavvy/errors-ui/lib/ErrorBoundary';
import UnexpectedErrorMessage from '@energysavvy/errors-ui/lib/UnexpectedErrorMessage';
import '@energysavvy/errors-ui/lib/index.css';
import '@energysavvy/customer-ui-components/style/index.css';

import App from './containers/App';
import configureStore from './store/configureStore.js';
import {getAllInit} from './actions';

export default function init() {
    registerInactivityLogout();
    const store = configureStore();
    store.dispatch(getAllInit())
        .then(() => {
            ReactDOM.render(
                (
                    <ErrorBoundary
                        error={<UnexpectedErrorMessage />}
                    >
                        <Provider store={store}>
                            <App />
                        </Provider>
                    </ErrorBoundary>
                ),
                document.getElementById('energysavvyAppContainer')
            );
        });
}
