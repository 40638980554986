import {INIT_ERROR} from '../constants';
import getText from '../language';

const initialState = null;

export default function(state = initialState, action) {
    switch (action.type) {
        case INIT_ERROR:
            switch (action.code) {
                case 'network_error':
                    return getText('error_code_network_error');
                default:
                    return getText('error_code_default');
            }
        default:
            return state;
    }
}
