import {RESUBMIT_BEGIN, UPDATE_SURVEY_ID} from '../constants';

const initialState = null;

export default function surveyId(state = initialState, action) {
    switch (action.type) {
        case RESUBMIT_BEGIN:
        case UPDATE_SURVEY_ID:
            return action.surveyId;
        default:
            return state;
    }
}
