import {RETRIEVE_META_RECEIVE} from '../constants';

const initialState = {};

export default function pathMeta(state = initialState, action) {
    switch (action.type) {
        case RETRIEVE_META_RECEIVE:
            const configData = action.payload;
            return configData.included.reduce((accum, current) => {
                if (current.type === 'path-meta') {
                    accum[current.id] = current.attributes;
                }
                return accum;
            }, {});
        default:
            return state;
    }
}
