import PropTypes from 'prop-types';
import React from 'react';
import {connect} from 'react-redux';
import {Alert, AppContainer} from '@energysavvy/customer-ui-components';

import Intro from './Intro';
import {STATUS} from '../reducers/initStatus';


function App({initError, initStatus, theme}) {
    let content;
    switch (initStatus) {
        case STATUS.COMPLETE:
            content = (<Intro />);
            break;
        case STATUS.ERROR:
        default: // In the case of init service request errors
            content = (
                <Alert
                    alertLevel={Alert.LEVEL.NOTIFICATION}
                    size={Alert.SIZE.STANDARD}
                    text={initError}
                />
            );
            break;
    }


    return (
        <AppContainer theme={theme}>
            {content}
        </AppContainer>
    );
}

App.propTypes = {
    initError: PropTypes.string,
    initStatus: PropTypes.string,
    theme: PropTypes.objectOf(PropTypes.string),
};

function mapStateToProps(state) {
    return {
        initStatus: state.initStatus,
        initError: state.initError,
        theme: state.theme,
    };
}

export default connect(mapStateToProps)(App);
