import PropTypes from 'prop-types';
import React from 'react';

import styles from './Fieldset.css';


export default function Fieldset(props) {
    const {
        style, helpText, legend, role, isInvalid, isRequired,
    } = props;

    const helpElement = helpText ? <span className={styles.helpText}>{helpText}</span> : null;
    const legendElement = legend ? <legend className={styles.legend}>{legend}</legend> : null;

    const ariaAttrs = {
        'aria-invalid': isInvalid,
        'aria-required': isRequired,
    };

    return (
        <fieldset className={style || styles.fieldset} role={role} {...ariaAttrs}>
            {legendElement}
            {helpElement}
            {props.children}
        </fieldset>
    );
}

Fieldset.displayName = 'Fieldset';

Fieldset.propTypes = {
    helpText: PropTypes.string,
    legend: PropTypes.string,
    style: PropTypes.string,
    children: PropTypes.node,
    /** ARIA */
    role: PropTypes.string,
    isInvalid: PropTypes.bool,
    isRequired: PropTypes.bool,
};
