import {RETRIEVE_CONFIG_RECEIVE} from '../constants';

const initialState = {};

export default function config(state = initialState, action) {
    switch (action.type) {
        case RETRIEVE_CONFIG_RECEIVE:
            const configData = action.payload.data.attributes;
            return {
                summaryTitle: configData.summary_title,
                summaryContent: configData.summary_content,
                otherHomeTypeDetail: configData.other_home_type_detail,
            };
        default:
            return state;
    }
}
