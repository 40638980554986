import {CHANGE_HOME_TYPE} from '../constants';

const initialState = null;

export default function homeType(state = initialState, action) {
    switch (action.type) {
        case CHANGE_HOME_TYPE:
            return action.homeType;
        default:
            return state;
    }
}
