import PropTypes from 'prop-types';

export function arrayContains(config) {
    return (props, propName, ...rest) => {
        const value = props[propName];
        const isNotArray = PropTypes.array(props, propName, ...rest);
        if (isNotArray) {
            return isNotArray;
        }

        for (const index of Object.keys(config)) {
            const typeChecker = config[index];
            const doesNotMatchConfig = typeChecker(value, index, ...rest);
            if (doesNotMatchConfig) {
                return doesNotMatchConfig;
            }
        }
        return undefined;
    };
}
