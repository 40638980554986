import {compose, withProps} from 'recompose';

import omitProps from '@hocs/omit-props';

export default compose(
    withProps(props => ({
        helpText: props.help_text,
        label: `${props.label}${props.isRequired ? '*' : ''}`,
        placeholder: props.showPlaceholder ? `${props.label}${props.isRequired ? '*' : ''}` : undefined,
        options: props.choiceLabels,
        type: 'text',
        onChange(event) {
            return props.onValueChange(event.target.value);
        },
    })),
    omitProps('showPlaceholder', 'onValueChange'),
);
