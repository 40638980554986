import cookies from 'browser-cookies';
import format from 'string-format';

import en from './locales/en.json';
import es from './locales/es.json';

const cache = {};

function loadCatalog(locale) {
    switch (locale) {
        case 'es':
            return es;
        case 'en':
        default:
            return en;
    }
}

function getCatalog() {
    cache.catalog = cache.catalog || loadCatalog(cookies.get('locale'));
    return cache.catalog;
}

export default function getText(text, formatParams = null) {
    const catalogText = getCatalog()[text];
    if (formatParams) {
        return format(catalogText, formatParams);
    }
    return catalogText;
}
