import {createSelector} from 'reselect';

import {
    SINGLE_FAMILY,
    TOWNHOME,
    APARTMENT_OR_CONDO,
    OTHER,
} from '../constants';
import getText from '../language';


function questionsSelector(state) { return state.formMeta.questions; }
function homeTypeSelector(state) { return state.homeType; }
function pathMetaSelector(state) { return state.pathMeta; }

export const displayedQuestionsSelector = createSelector(
    questionsSelector, homeTypeSelector,
    (origQuestions, homeType) => {
        const questions = [...origQuestions];
        if (homeType === APARTMENT_OR_CONDO) {
            questions.splice(
                questions.findIndex(question => question.slug === 'floors'),
                1,
            );
        } else if (homeType !== OTHER) {
            questions.splice(
                questions.findIndex(question => question.slug === 'building_height'),
                1,
            );
        }
        return questions;
    }
);

export const homeTypesMetaSelector = createSelector(
    pathMetaSelector,
    pathMeta => {
        const otherPathMeta = {
            value: OTHER,
            label: getText(OTHER),
        };
        return [
            SINGLE_FAMILY,
            TOWNHOME,
            APARTMENT_OR_CONDO,
        ].map(homeType => ({
            value: homeType,
            label: pathMeta[homeType].label,
            helpText: pathMeta[homeType].help_text,
        }))
            .concat(otherPathMeta);
    }
);
