import PropTypes from 'prop-types';
import React from 'react';
import omit from 'lodash/omit';

import Field from './Field.jsx';
import Input from './Input.jsx';

export default function InputField(props) {
    const {
        style, slug, type, name, label, value,
        onChange, helpText, errors, isRequired,
        hideLabel, onBlur, onFocus, hideErrors,
    } = props;
    const propKeys = ['is_asked', 'is_required', ...Object.keys(InputField.propTypes)];
    const extraHTMLAttrs = omit(props, propKeys);

    return (
        <Field {...{style, type, label, slug, helpText, errors, hideLabel, hideErrors}}>
            <Input
                {...{slug, type, name, isRequired, value, onChange, onBlur, onFocus}}
                {...extraHTMLAttrs}
            />
        </Field>
    );
}

InputField.displayName = 'InputField';

InputField.propTypes = {
    style: PropTypes.string,
    slug: PropTypes.string.isRequired,
    type: PropTypes.string,
    name: PropTypes.string,
    label: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
    ]),
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    onFocus: PropTypes.func,
    helpText: PropTypes.string,
    errors: Field.propTypes.errors,
    isRequired: PropTypes.bool,
    hideLabel: PropTypes.bool,
    hideErrors: PropTypes.bool,
};

InputField.defaultProps = {
    type: 'text',
    isRequired: false,
    hideLabel: false,
    hideErrors: false,
};
