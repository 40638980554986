import cookie from 'cookie';
import omit from 'lodash/omit';
import omitBy from 'lodash/omitBy';
import pick from 'lodash/pick';
import qs from 'query-string';
import {FORM_ERROR} from 'final-form';
import generateUuid from 'uuid';

import {
    errors as fetchErrors,
    fetchUrl,
    fetchUrlWithActions,
    utils as fetchUtils,
} from '@energysavvy/customer-ui-fetch-actions';

import translateErrors from '../utils/errors';
import logger from '../logger';

import {
    CHANGE_HOME_TYPE,
    INIT_RECEIVE,
    INIT_ERROR,
    RESUBMIT_BEGIN,
    RETRIEVE_CONFIG_RECEIVE,
    RETRIEVE_META_RECEIVE,
    RETRIEVE_THEME_RECEIVE,
    UPDATE_ANSWER,
    UPDATE_SURVEY_ID,
    STREET_ADDRESS_ONE,
    STREET_ADDRESS_TWO,
    ZIP_CODE,
    START_SUBMIT_INTRO,
    ERROR_SUBMIT_INTRO,
} from '../constants';

const PREMISE_KEYS = [
    STREET_ADDRESS_ONE,
    STREET_ADDRESS_TWO,
    ZIP_CODE,
];

const PROVISIONAL_SURVEY_ID_COOKIE_NAME = 'residential-provisional-survey-uuid';
const SURVEY_ID_COOKIE_NAME = 'residential-survey-uuid';

const JSON_API_HEADER = {'Content-Type': 'application/vnd.api+json'};

/*
  Sometimes googlebots leave no user agent indicating they are googlebots.
  Google's random() function is deterministic, which means a consistent first
  `surveyId` generated. This can result in events with an errorCode of
  "event_already_exists" in the ROA event service. Survey IDs producing these
  error events can be added here to prevent duplicate event errors and
  unintentional bot page views.
*/
const SUSPECTED_GOOGLE_BOT_SURVEY_IDS = new Set([
    '43075901-0ad5-413c-ace7-0a2821790dbf',
    'af829a9c-fa98-4b60-b61b-f930e2cd5fcb',
    '7af9342a-8239-4cf0-8195-464b66c09db5',
    '44fd60fc-1d34-470a-8dce-31e97b184f54',
]);

function initReceive(initialAnswers) {
    return {
        type: INIT_RECEIVE,
        initialAnswers,
    };
}

function updateSurveyId(surveyId) {
    return {
        type: UPDATE_SURVEY_ID,
        surveyId,
    };
}

function initError(code) {
    return {
        type: INIT_ERROR,
        code,
    };
}

function generateUuidCookieExpirationDate() {
    const cookieDate = new Date();
    cookieDate.setTime(cookieDate.getTime() + (3600 * 1000 * 24 * 365 * 10)); // Set to expire 10 years from initialization date
    return cookieDate;
}

function writeCookie(name, value) {
    const uuidCookieExpiration = generateUuidCookieExpirationDate();
    document.cookie = cookie.serialize(name, value, {path: '/', expires: uuidCookieExpiration, secure: true, sameSite: 'lax'});
}

function removeCookie(name) {
    document.cookie = cookie.serialize(name, '', {path: '/', maxAge: 0});
}

function logOnResponseError(response, errorData = {}) {
    if (response.status < 200 || response.status >= 300) {
        const logData = {
            ...errorData,
            code: 'response_error',
            status: response.status,
            url: response.url,
        };

        response.json()
            .then(json => {
                logger.error({
                    ...logData,
                    errorCode: json.errors[0].code,
                    errorDetail: json.errors[0].detail || null,
                });
            })
            .catch(() => {
                logger.error(logData);
            });
    }
}

function postEvent(name, surveyId) {
    const assessmentEventsServiceUrl = '/residential-assessment-events/events';
    const event = {
        attributes: {
            name,
            survey_id: surveyId,
        },
    };

    return fetch(
        assessmentEventsServiceUrl,
        {
            method: 'POST',
            headers: JSON_API_HEADER,
            credentials: 'same-origin',
            body: JSON.stringify({data: event}),
        }
    )
        .then(
            response => {
                logOnResponseError(response, {
                    surveyId: surveyId,
                    eventName: name,
                    message: 'postEvent error',
                    requestBody: {data: event},
                });
                return response;
            },
            () => {}
        );
}

function generateSurveyId() {
    const surveyId = generateUuid();
    if (SUSPECTED_GOOGLE_BOT_SURVEY_IDS.has(surveyId)) {
        logger.log('info', {
            message: 'suspected_googlebot_uuid_generation',
        });
    } else {
        postEvent('start-page-unique-visit', surveyId);
    }
    writeCookie(PROVISIONAL_SURVEY_ID_COOKIE_NAME, surveyId);
    return surveyId;
}

function answersObjectFromQueryString(queryString) {
    const queryObject = qs.parse(queryString);
    const answers = {};
    if (queryObject.src_code || queryObject.src) {
        answers.campaign_code = queryObject.src_code || queryObject.src;
    }
    Object.keys(queryObject).forEach(arg => {
        const match = /^answers\[(.*)\]$/.exec(arg);
        if (match) {
            const slug = match[1];
            if (PREMISE_KEYS.includes(slug)) {
                // Premise keys are special cased because they are always strings,
                // but can consist of only digits
                answers[slug] = queryObject[arg];
            } else {
                try {
                    answers[slug] = JSON.parse(queryObject[arg]);
                } catch (e) {
                    answers[slug] = queryObject[arg];
                }
            }
        }
    });
    return answers;
}


function isLikelyRobot() {
    const dataElement = document.getElementById('energysavvy-data');
    const isLikelyRobotData = dataElement && dataElement.getAttribute('data-is-likely-robot') || 'false';
    return isLikelyRobotData === 'true';
}


export function getAllInit() {
    const languageCode = cookie.parse(document.cookie).locale || 'en';
    const initialAnswers = answersObjectFromQueryString(document.location.search);
    return dispatch => {
        // If no cookie and looks to be a robot, don't generate a survey id (it
        // will be generated on submission, since generation of survey id
        // triggers unique-start-page-visited event creation
        let provisionalSurveyId = cookie.parse(document.cookie)[PROVISIONAL_SURVEY_ID_COOKIE_NAME] || null;
        if (provisionalSurveyId) {
            dispatch(updateSurveyId(provisionalSurveyId));
        } else if (!isLikelyRobot()) {
            provisionalSurveyId = generateSurveyId();
            dispatch(updateSurveyId(provisionalSurveyId));
        }
        return Promise.all([
            dispatch(
                fetchUrlWithActions(
                    '/residential-display/theme',
                    {receive: RETRIEVE_THEME_RECEIVE},
                    {},
                    {
                        logger,
                        shouldRejectOnError: true,
                    }
                )
            ),
            dispatch(
                fetchUrlWithActions(
                    `/residential-display/intro?locale=${languageCode}`,
                    {receive: RETRIEVE_CONFIG_RECEIVE},
                    {},
                    {
                        logger,
                        shouldRejectOnError: true,
                    }
                )
            ),
            dispatch(
                fetchUrlWithActions(
                    `/residential-surveys/meta?locale=${languageCode}`,
                    {receive: RETRIEVE_META_RECEIVE},
                    {},
                    {
                        logger,
                        shouldRejectOnError: true,
                    }
                )
            ),
        ])
            .then(() => dispatch(initReceive(initialAnswers)))
            .catch(error => {
                const errorCodes = error.errorCodes || [{code: 'default'}];
                dispatch(initError(errorCodes[0].code));
            });
    };
}

export function changeHomeType(homeType) {
    return {
        type: CHANGE_HOME_TYPE,
        homeType,
    };
}

export function updateAnswer(field, answer) {
    return {
        type: UPDATE_ANSWER,
        field,
        answer,
    };
}

export function beginResubmit(surveyId) {
    return {
        type: RESUBMIT_BEGIN,
        surveyId,
    };
}


function directToSurvey(surveyId) {
    removeCookie(PROVISIONAL_SURVEY_ID_COOKIE_NAME);
    writeCookie(SURVEY_ID_COOKIE_NAME, surveyId);
    document.location = '/residential/survey/';
}


function getSurveyBody(answers, homeType, surveyId) {
    return {
        data: {
            id: surveyId,
            type: 'residential-surveys',
            attributes: {
                answers,
                survey_path: homeType ? homeType.split('-').join('_') : homeType,
                source: 'online',
            },
        },
    };
}

function isValidZip(zipCode) {
    return /^\d{5}$/.test(zipCode);
}

function startSubmitIntro() {
    return {
        type: START_SUBMIT_INTRO,
    };
}

function errorSubmitIntro() {
    return {
        type: ERROR_SUBMIT_INTRO,
    };
}

function logError(error) {
    const errorMapping = {
        ...fetchUtils.errorToLogLevelMapper,
        [fetchErrors.ClientError]: () => 'info',
    };
    fetchUtils.logErrorWithMapping(error, errorMapping, logger);
}

function fetchCityAndState(zipCode) {
    if (!isValidZip(zipCode)) {
        return Promise.resolve({});
    }

    return fetchUrl(`/locations/zip-codes/${zipCode}`)
        .then(json => (json.data ? fetchUtils.parseLocation(json) : {}))
        .catch(error => {
            logError(error);
            return Promise.resolve({});
        });
}

function getPremiseBody(premiseAnswers, surveyId, locationData) {
    const streetAddressOne = premiseAnswers.street_address || '';
    const unitNumber = premiseAnswers.unit_number && `Unit ${premiseAnswers.unit_number}` || '';

    const streetAddress = (streetAddressOne && unitNumber) ?
        `${streetAddressOne} ${unitNumber}` :
        `${streetAddressOne}${unitNumber}`;  // Displays nicely if only one or the other provided

    return {
        data: {
            id: generateUuid(),
            type: 'premises',
            attributes: {
                'physical-address': omitBy({
                    'street-address-1': streetAddress,
                    city: locationData && locationData.cityName,
                    state: locationData && locationData.stateAbbreviation,
                    'postal-code': premiseAnswers.zip_code,
                    country: 'USA',
                }, value => !value),
                source: 'residential-online-assessment',
            },
            relationships: {
                'residential-survey': {
                    data: {
                        id: surveyId,
                        type: 'external-resources',
                    },
                },
            },
        },
    };
}

function createPremise(premiseAnswers, surveyId, locationData) {
    const premiseBody = getPremiseBody(premiseAnswers, surveyId, locationData);
    return fetchUrl(
        '/accounts/premises',
        {
            method: 'POST',
            headers: JSON_API_HEADER,
            body: JSON.stringify(premiseBody),
        },
        // Logging here because we shouldn't receive ClientError for createPremise calls
        {logger}
    );
}

function createSurvey(answers, homeType, surveyId) {
    const surveyBody = getSurveyBody(answers, homeType, surveyId);
    return fetchUrl(
        '/residential-surveys/surveys',
        {
            method: 'POST',
            headers: JSON_API_HEADER,
            body: JSON.stringify(surveyBody),
        },
    );
}

export function submitIntro(answers) {
    return (dispatch, getState) => {
        const state = getState();
        const homeType = state.homeType;
        const surveyAnswers = omit(answers, [STREET_ADDRESS_ONE, STREET_ADDRESS_TWO]);
        const premiseAnswers = pick(answers, PREMISE_KEYS);
        let {surveyId} = getState();
        const {formMeta: {onSubmitValidate}} = getState();

        // If no surveyId already generated (because a likely robot) generate
        // one now to fire unique-start-page-visited event
        if (!surveyId) {
            surveyId = generateSurveyId();
            dispatch(updateSurveyId(surveyId));
        }

        dispatch(startSubmitIntro());
        return onSubmitValidate(answers)
            .catch(error => Promise.reject({[FORM_ERROR]: error}))
            .then(() => createSurvey(surveyAnswers, homeType, surveyId))
            .then(() => fetchCityAndState(premiseAnswers.zip_code))
            .then(locationData => createPremise(premiseAnswers, surveyId, locationData))
            .then(() => postEvent('assessment-start', surveyId).catch(() => {}))
            .then(() => directToSurvey(surveyId))
            .catch(error => submitError(dispatch, error)); // eslint-disable-line no-use-before-define
    };
}

function resubmit() {
    const surveyId = generateSurveyId();
    return dispatch => {
        dispatch(beginResubmit(surveyId));
    };
}

export function submitError(dispatch, error) {
    dispatch(errorSubmitIntro());
    if (error[FORM_ERROR]) {
        return error;
    }
    logError(error);
    const errorCodes = error.errorCodes || [{code: 'default'}];
    const duplicateSurveyIdError = errorCodes.find(item => item.code === 'resource_already_exists');
    if (duplicateSurveyIdError) {
        return dispatch(resubmit());
    }
    return translateErrors(errorCodes);
}
