import getText from '../language';
import {FORM_ERROR} from 'final-form';


function translatePointer(pointer) {
    return pointer.slice(pointer.lastIndexOf('/') + 1);
}

function translateFieldErrorCodeAndMetaToMessage(code, meta) {
    switch (code) {
        case 'type_invalid': // TODO (CUR-2908): Only expected for street address
        case 'required':
            return getText('field_error_field_required');
        case 'range_invalid':
        case 'out_of_range':
            const {min, max} = meta;
            return getText('field_error_out_of_range', {min, max});
        case 'invalid':
            return getText('field_error_invalid');
        case 'not_in_service_area':
            return getText('field_error_not_in_service_area');
        default:
            return getText('field_error_default');
    }
}

function translateGlobalErrorCodeToMessage(code) {
    switch (code) {
        case 'network_error':
            return getText('survey_submit_network_error');
        case 'server_error':
        case 'non_json_response':
        case 'default':
        default:
            return getText('error_code_default');
    }
}

export default function translateErrors(errors) {
    const errorObject = errors.reduce((accumulator, error) => {
        const {code, meta, source: {pointer} = {}} = error;
        const key = pointer && translatePointer(pointer);
        if (!key) { // global error
            accumulator[FORM_ERROR] = [translateGlobalErrorCodeToMessage(code)];
        } else {
            const message = translateFieldErrorCodeAndMetaToMessage(code, meta);

            accumulator[key] = accumulator[key] || [];
            accumulator[key].push(message);
        }

        return accumulator;
    }, {});

    // Don't show state error as a field error since not asked.
    // If there is a state error and zip code error, just show the zip code error since that
    // supercedes.
    // If there is no zip code error, convert the state error to a global error since
    // something went wrong translating the zip to a state.
    if ('state' in errorObject) {
        delete errorObject.state;
        if (!('zip_code' in errorObject)) {
            errorObject[FORM_ERROR] = [translateGlobalErrorCodeToMessage('default')];
        }
    }
    return errorObject;
}
