import {START_SUBMIT_INTRO, ERROR_SUBMIT_INTRO} from '../constants';

const initialState = false;

export default function isSubmitting(state = initialState, action) {
    switch (action.type) {
        case START_SUBMIT_INTRO:
            return true;
        case ERROR_SUBMIT_INTRO:
            return false;
        default:
            return state;
    }
}
