import {compose, withProps} from 'recompose';

import omitProps from '@hocs/omit-props';

export default compose(
    withProps(props => ({
        helpText: props.help_text,
        label: `${props.label}${props.isRequired ? '*' : ''}`,
        options: props.choiceLabels.map(({label, slug}) => ({label, value: slug})),
        onChange(event) {
            return props.onValueChange(event.target.value);
        },
    })),
    omitProps('onValueChange'),
);
