import PropTypes from 'prop-types';
import React from 'react';
import get from 'lodash/get';

import {Button} from '@energysavvy/customer-ui-components';
import {QuestionForm} from '@energysavvy/form-ui-components';
import reduxQuestionForm from '@energysavvy/redux-form-enhancers';

import getText from '../language';
import logger from '../logger';
import ErrorList from './fields/ErrorList';
import PremiseAddress from './PremiseAddress';

import styles from './PropertyForm.css';


export default function PropertyForm(props) {
    return (
        <div className={styles.wrapper}>
            <QuestionForm
                questions={props.questions}
                groups={props.groups}
                componentMapping={props.componentMapping}
                groupComponentMapping={{
                    PremiseAddress: PremiseAddress,
                }}
            />
            <div className={styles.formControls}>
                {props.errorMessages.length > 0 ? <ErrorList errors={props.errorMessages} slug="global" /> : null}
                <div className={styles.formAction}>
                    <Button
                        text={getText('continue_button_text')}
                        type="submit"
                        icon={(() => {
                            // Attempt to behave normally, but if an error is thrown,
                            // log some diagnostic information so we can try to figure
                            // out what's going on (XPR-73).
                            try {
                                return Button.ICON.CONTINUE;
                            } catch (error) {
                                // Ignoring this eslint rule because this code is temporary.
                                // eslint-disable-next-line no-inner-declarations
                                function info(obj, path) {
                                    const value = path ? get(obj, path, 'INACCESSIBLE') : obj;
                                    return {
                                        typeof: typeof value,
                                        value,
                                        valueJSON: JSON.stringify(value),
                                        keys: value ? Object.keys(value) : null,
                                    };
                                }
                                logger.critical({
                                    message: "It's that Button.ICON.CONTINUE bug (XPR-73)",
                                    originalError: error,
                                    Button: info(Button),
                                    'Button.ICON': info(Button, 'ICON'),
                                    'Button.ICON.CONTINUE': info(Button, 'ICON.CONTINUE'),
                                });
                                return undefined;
                            }
                        })()}
                        disabled={props.isSubmitting}
                    />
                </div>
            </div>
        </div>
    );
}

PropertyForm.displayName = 'PropertyForm';

PropertyForm.propTypes = {
    errorMessages: PropTypes.arrayOf(PropTypes.string).isRequired,
    isSubmitting: PropTypes.bool.isRequired,
    questions: reduxQuestionForm.propTypes.questions,
    groups: QuestionForm.propTypes.groups,
    componentMapping: reduxQuestionForm.propTypes.componentMapping,
};

PropertyForm.defaultProps = {
    errorMessages: [],
};
