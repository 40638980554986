import PropTypes from 'prop-types';
import React from 'react';

import styles from './Select.css';


export default function Select(props) {
    const {
        slug, options, value, onChange,
        describedBy, isInvalid, isRequired,
    } = props;

    const optionNodes = options.map(option => {
        return (
            <option key={option.label} value={option.value}>
                {option.label}
            </option>
        );
    });

    const ariaAttrs = {
        'aria-describedby': describedBy,
        'aria-invalid': isInvalid,
        'aria-required': isRequired,
    };

    return (
        <select className={styles.select} name={slug} id={slug} value={value} onChange={onChange} {...ariaAttrs}>
            <option value="" hidden="hidden" aria-hidden />
            {optionNodes}
        </select>
    );
}

Select.displayName = 'Select';

Select.propTypes = {
    slug: PropTypes.string.isRequired,
    options: PropTypes.arrayOf(PropTypes.shape({
        value: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
    })).isRequired,
    value: PropTypes.string,
    onChange: PropTypes.func,
    describedBy: PropTypes.string,
    isInvalid: PropTypes.bool,
    isRequired: PropTypes.bool,
};
