import {RETRIEVE_THEME_RECEIVE} from '../constants';


function keysToCamel(snakeKeyedObject) {
    return Object.keys(snakeKeyedObject).reduce(
        (camelKeyedObject, snakeKey) => {
            const camelKey = snakeKey.replace(/_\w/g, match => match[1].toUpperCase());
            camelKeyedObject[camelKey] = snakeKeyedObject[snakeKey];
            return camelKeyedObject;
        },
        {}
    );
}

export default function theme(state = {}, action) {
    switch (action.type) {
        case RETRIEVE_THEME_RECEIVE:
            const snakeCasedTheme = action.payload.data.attributes;
            return keysToCamel(snakeCasedTheme);
        default:
            return state;
    }
}
