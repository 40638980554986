import {combineReducers} from 'redux';

import config from './config';
import formMeta from './formMeta';
import homeType from './homeType';
import initError from './initError';
import initStatus from './initStatus';
import isSubmitting from './isSubmitting';
import pathMeta from './pathMeta';
import surveyId from './surveyId';
import theme from './theme';

export default combineReducers({
    config,
    formMeta,
    homeType,
    initError,
    initStatus,
    isSubmitting,
    pathMeta,
    surveyId,
    theme,
});
