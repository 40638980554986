import PropTypes from 'prop-types';
import React from 'react';

import Field from './Field.jsx';
import Select from './Select.jsx';

export default function DropdownSelectorField(props) {
    const {
        slug, label, options, value, onChange,
        helpText, errors, isRequired,
    } = props;

    return (
        <Field label={label} slug={slug} helpText={helpText} errors={errors}>
            <Select
                slug={slug}
                options={options}
                isRequired={isRequired}
                value={value}
                onChange={onChange}
            />
        </Field>
    );
}

DropdownSelectorField.displayName = 'DropdownSelectorField';

DropdownSelectorField.propTypes = {
    slug: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    options: PropTypes.arrayOf(PropTypes.shape({
        value: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
    })).isRequired,
    value: PropTypes.string,
    onChange: PropTypes.func,
    helpText: PropTypes.string,
    errors: Field.propTypes.errors,
    isRequired: PropTypes.bool,
};
