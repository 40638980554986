import {mapMetaToAsyncValidate, mapMetaToQuestions, mapMetaToGroups} from '@energysavvy/redux-form-enhancers';

import {INIT_RECEIVE, RETRIEVE_META_RECEIVE, FORM_NAME} from '../constants';

function isRelatedToIntroSection(resource) {
    return resource.relationships &&
    resource.relationships.section &&
    resource.relationships.section.data &&
    resource.relationships.section.data.id === FORM_NAME;
}

const initialState = {};

export default function formMeta(state = initialState, action) {
    switch (action.type) {
        case INIT_RECEIVE:
            return {
                ...state,
                initialValues: action.initialAnswers,
            };
        case RETRIEVE_META_RECEIVE:
            const configData = action.payload;
            return {
                ...state,
                questions: mapMetaToQuestions(configData, isRelatedToIntroSection),
                groups: mapMetaToGroups(configData),
                onSubmitValidate: mapMetaToAsyncValidate(configData),
            };
        default:
            return state;
    }
}
