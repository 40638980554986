import {INIT_RECEIVE, INIT_ERROR} from '../constants';

export const STATUS = {
    PENDING: 'pending',
    COMPLETE: 'complete',
    ERROR: 'error',
};

const initialState = STATUS.PENDING;

export default function(state = initialState, action) {
    switch (action.type) {
        case INIT_RECEIVE:
            return STATUS.COMPLETE;
        case INIT_ERROR:
            return STATUS.ERROR;
        default:
            return state;
    }
}
