import flowRight from 'lodash/flowRight';
import {connect} from 'react-redux';
import {withProps} from 'recompose';
import {bindActionCreators} from 'redux';

import finalQuestionForm from '@energysavvy/redux-form-enhancers';

import Introform from '../components/IntroForm.jsx';
import {displayedQuestionsSelector, homeTypesMetaSelector} from '../selectors';
import {changeHomeType, submitIntro} from '../actions';
import selectQuestion from '../enhancers/selectQuestion';
import integerQuestion from '../enhancers/integerQuestion';
import textQuestion from '../enhancers/textQuestion';
import DropdownSelectorField from '../components/fields/DropdownSelectorField.jsx';
import InputField from '../components/fields/InputField.jsx';
import getText from '../language';

function mapStateToProps(state) {
    return {
        homeTypes: homeTypesMetaSelector(state),
        homeType: state.homeType,
        questions: displayedQuestionsSelector(state),
        groups: state.formMeta.groups,
        initialValues: state.formMeta.initialValues,
        isSubmitting: state.isSubmitting,
        otherHomeTypeDetail: state.config.otherHomeTypeDetail,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        changeHomeType,
        onSubmit: submitIntro,
    }, dispatch);
}

const enhance = flowRight(
    withProps({
        componentMapping: {
            SelectQuestion: selectQuestion(DropdownSelectorField),
            select: selectQuestion(DropdownSelectorField),
            IntegerQuestion: integerQuestion(InputField),
            integer: integerQuestion(InputField),
            text: textQuestion(InputField),
        },
        requiredText: getText('required_information'),
        fieldErrorText: getText('field_error_text'),
    }),
    connect(mapStateToProps, mapDispatchToProps),
    finalQuestionForm,
);

export default enhance(Introform);
