import PropTypes from 'prop-types';
import React from 'react';
import {FormSpy} from 'react-final-form';

import ErrorList from './fields/ErrorList';
import Fieldset from './fields/Fieldset.jsx';

import styles from './PremiseAddress.css';
import {
    STREET_ADDRESS_ONE,
    STREET_ADDRESS_TWO,
    ZIP_CODE,
} from '../constants/questionSlugs';
import {arrayContains} from '../utils/propTypes';


// Mirrors https://projects.evoworx.org/customer/redux-form-enhancers/blob/master/src/enhancers/fieldToQuestion.js
function getErrors(form, slug) {
    const {
        visited,
        submitFailed,
        errors = {},
        submitErrors = {},
    } = form;
    // Not checking for "active" to keep errors from moving around
    if (!visited[slug] && !submitFailed) {
        return [];
    }

    return (errors[slug] || [])
        .concat(submitErrors[slug] || []);
}

export default class PremiseAddress extends React.Component {
    constructor() {
        super();
        this.state = {errors: []};
        this.handleFormUpdate = this.handleFormUpdate.bind(this);
    }

    handleFormUpdate(form) {
        if (!form.submitFailed || form.submitting) {
            this.setState({errors: []});
            return;
        }
        const [
            streetAddressConfig,
            unitNumberConfig,
            zipCodeConfig,
        ] = this.props.questionsConfig;
        const streetAddressErrors = getErrors(form, STREET_ADDRESS_ONE);
        const unitNumberErrors = getErrors(form, STREET_ADDRESS_TWO);
        const zipCodeErrors = getErrors(form, ZIP_CODE);

        const errors = streetAddressErrors.map(error => `${streetAddressConfig.label}: ${error}`)
            .concat(unitNumberErrors.map(error => `${unitNumberConfig.label}: ${error}`))
            .concat(zipCodeErrors.map(error => `${zipCodeConfig.label}: ${error}`));

        this.setState({errors});
    }

    render() {
        const {
            questionsConfig,
            groupConfig: {
                label: legend,
                help_text: helpText,
            },
            componentMapping: {
                text: TextQuestion,
            },
        } = this.props;
        const [
            streetAddressConfig,
            unitNumberConfig,
            zipCodeConfig,
        ] = questionsConfig;
        return (
            <Fieldset
                legend={`${legend}*`}
                helpText={helpText}
            >
                <FormSpy
                    subscription={{
                        submitting: true,
                        submitFailed: true,
                        errors: true,
                        submitErrors: true,
                        visited: true,
                    }}
                    onChange={this.handleFormUpdate}
                />
                <TextQuestion
                    {...streetAddressConfig}
                    style={styles.streetAddressField}
                    hideLabel
                    hideErrors
                    showPlaceholder
                />
                <TextQuestion
                    {...unitNumberConfig}
                    style={styles.addressField}
                    hideLabel
                    hideErrors
                    showPlaceholder
                />
                <TextQuestion
                    {...zipCodeConfig}
                    style={styles.addressField}
                    hideLabel
                    hideErrors
                    showPlaceholder
                />
                <ErrorList
                    errors={this.state.errors}
                    slug="location"
                />
            </Fieldset>
        );
    }
}

PremiseAddress.displayName = 'PremiseAddress';

PremiseAddress.propTypes = {
    questionsConfig: arrayContains([
        PropTypes.shape({
            slug: PropTypes.oneOf([STREET_ADDRESS_ONE]),
            component: PropTypes.oneOf(['text']),
            is_required: PropTypes.oneOf([true]),
            label: PropTypes.string,
        }),
        PropTypes.shape({
            slug: PropTypes.oneOf([STREET_ADDRESS_TWO]),
            component: PropTypes.oneOf(['text']),
            is_required: PropTypes.oneOf([false]),
            label: PropTypes.string,
        }),
        PropTypes.shape({
            slug: PropTypes.oneOf([ZIP_CODE]),
            component: PropTypes.oneOf(['text']),
            is_required: PropTypes.oneOf([true]),
            label: PropTypes.string,
        }),
    ]),
    groupConfig: PropTypes.shape({
        label: PropTypes.string,
        help_text: PropTypes.string,
    }),
    componentMapping: PropTypes.shape({
        text: PropTypes.func,
    }),
    errors: PropTypes.arrayOf(PropTypes.string),
};
