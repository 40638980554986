export const CHANGE_HOME_TYPE = 'CHANGE_HOME_TYPE';
export const INIT_RECEIVE = 'INIT_RECEIVE';
export const INIT_ERROR = 'INIT_ERROR';
export const RESUBMIT_BEGIN = 'RESUBMIT_BEGIN';
export const RETRIEVE_CONFIG_RECEIVE = 'RETRIEVE_CONFIG_RECEIVE';
export const RETRIEVE_META_RECEIVE = 'RETRIEVE_META_RECEIVE';
export const RETRIEVE_THEME_RECEIVE = 'THEME_RECEIVE';
export const UPDATE_ANSWER = 'UPDATE_ANSWER';
export const UPDATE_SURVEY_ID = 'UPDATE_SURVEY_ID';
export const START_SUBMIT_INTRO = 'START_SUBMIT_INTRO';
export const ERROR_SUBMIT_INTRO = 'ERROR_SUBMIT_INTRO';
