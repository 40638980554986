import {applyMiddleware, createStore, compose} from 'redux';
import thunkMiddleware from 'redux-thunk';

import rootReducer from '../reducers';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export default function configureStore(initialState) {
    return createStore(rootReducer, initialState, composeEnhancers(
        applyMiddleware(thunkMiddleware)
    ));
}
